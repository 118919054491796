<template>
    <div class="body" ref="pdf" id="pdf">
        <iframe frameborder="0" target="_top"  :src="src">
            
        </iframe>
        
    </div>
</template>

<script>
    var tid, tsid, timer, lid;
    import { getTaskDetail, finishDocLessonProcess } from '@/api/task.js';
    import { toast, checkLogin } from '@/utils/common.js';
    // import { getUser } from '../api/common.js';
    // import { setWaterMark, removeWatermark } from "../utils/watermark";
    export default {
        data() {
            return{
                src: '',
                info: null,
                tsid: '',
                tid: '',
                cur: '',
                list: [],
            }
            
        },
        activated() {
            tid = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            this.cur = +this.$route.query.cur;// 当前课程索引
            lid = this.$route.query.lid; //当前课程 首页传过来的不准确
            checkLogin().then(()=> {
                this.getDetail(tid, tsid);
            })
            
            this.hideTab();
        },
        deactivated() {
            clearInterval(timer);
            // removeWatermark();
        },
        methods:{
            getCur(){
                for (var i = 0; i < this.list.length; i++) {
                    var item = this.list[i];
                    if(item['lid'] == lid){
                        return i;
                    }
                }
            },
            // getUser(){
            //     getUser().then((ret)=> {
            //         if(ret){
            //             //this.info = ret.data;
            //             setWaterMark(ret.data.dingtalk.nickname, formatDate());
            //         }
            //     }) 
            // },
            getDetail(tid, tsid){
                getTaskDetail(tid, tsid).then((ret)=>{
                    var data = ret.data;
                    this.list = data.lessions;
                    this.cur = this.getCur();
                    this.info = data.lessions[this.cur];
                    this.src = `https://view.officeapps.live.com/op/view.aspx?src=${this.info['file_url']}`;                  
                    // this.getUser();
                    
                    // 第一次看
                    if(this.info.speed_num == 1){
                        return;
                    }
                    timer = setTimeout(()=>{
                        // finishViewDocProcess
                        finishDocLessonProcess(this.info.tluid, tid).then((ret)=>{
                            // 并不需要标记
                            console.log('已完成该课程', ret);
                        }).catch((err)=>{
                            toast(err.msg|| '更新总进度失败！', 'error')
                        })
                    },1000*60*1);
                });
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    iframe{
        width: 100%;
        min-height: 100vh;
    }
</style>